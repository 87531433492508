






















import { computed, defineComponent } from "@vue/composition-api";
import accountStateRef from "@/apps/accounts/modules/store";
import APP_CONFIG from "../modules/config";

export default defineComponent({
  name: "MainAppLayout",
  components: {
    BottomNavbar: () => import("@/apps/core/components/BottomNavbar.vue"),
    NavBar: () => import("@/apps/core/components/NavBar.vue"),
  },
  setup() {
    const userId = computed(() => accountStateRef.me.id);
    const keepAliveMax = 10; // TODO: Pindahkan ke APP_CONFIG

    return {
      // semua component yang berakhiran Form, Update, Detail,
      // atau Create di-exclude dari keep-alive
      exclude: new RegExp(APP_CONFIG.keepAliveExcludePattern),
      keepAliveMax,
      userId,
    };
  },
});
